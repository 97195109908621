<template>
  <div class="reports-pagination" v-if="false">
    <el-pagination
      background
      :current-page.sync="$_page"
      :page-sizes="[10, 20, 30, 40, 60]"
      :page-size="$_perPage"
      layout="sizes, prev, pager, next, total"
      :total="$store.state.reportsStore.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
// import { mapMutations } from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin.js'

export default {
  // data() {
  //   return {
  //     currentPage2: 1
  //   }
  // },

  mixins: [paginationMixin],

  methods: {
    // ...mapMutations(['SET_QUERY_PARAM']),
    handleSizeChange(size) {
      // this.SET_QUERY_PARAM({ name: 'perPage', value: size })
      this.$emit('limit', size || 10)
    },
    handleCurrentChange(current) {
      // this.SET_QUERY_PARAM({ name: 'currentPage', value: current })
      this.$emit('page', current || 1)
    }
  }
}
</script>

<style>
.reports-pagination {
  background-color: white;
  display: flex;
  justify-items: flex-start;
  align-items: center;
  height: 100%;
}
</style>
